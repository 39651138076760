<template>
    <v-dialog v-if="checkCategory" v-model="categoryDialog" persistent max-width="1200px">
        <v-form
                ref="formDish"
                v-model="validCategory"
                lazy-validation
                @submit.prevent="validateCategory()!=true?null:edit()">
            <v-card>
                <v-card-title>
                    <div class="headline">Category</div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row align="center" >
<!--                          v-if="service.serviceType.name==='CLASSIC' || service.serviceType.name==='SHARING'"-->
                            <v-col cols="12" md="6">
                                    <v-select
                                        id="category_type"
                                            v-model="localCategory.categoryType"
                                            :items="dishTypes"
                                            item-text="displayName"
                                            :return-object=true
                                            label="Category Type"
                                            :rules="[v => !!v && v.length !== 0 || 'You must select one Type !']"
                                            required
                                            @change="defaultCategoryDescription"
                                    ></v-select>
                            </v-col>
<!--                          v-if="localCategory.categoryType !==undefined && (localCategory.categoryType.name=='OTHER' || localCategory.categoryType.name=='FOOD')"-->
                          <v-col cols="12" md="6" >
                              <v-text-field
                                  id="category_description"
                                  label="Category description"
                                  v-model="localCategory.name"
                                  type="text"
                                  :rules="[v => !!v && v.length !== 0 || 'You must enter a description !',  v => this.checkCategoryName(v) || 'The name should be unique']"
                                  required
                              >
                              </v-text-field>

                          </v-col>
                          <v-col cols="12" md="6" v-if="localCategory.categoryType.name!=='KID'">
                            <v-radio-group
                                id="pricing_type"
                                v-model="localCategory.pricingType"
                                row
                                label="Pricing :"
                                :rules="[v => v!==undefined  || 'This field is required!']"
                            >
                              <v-radio v-for="option in pricingType" :key="option.name"
                                       :label="option.displayName"
                                       :value="option"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="12" md="6" v-if="localCategory.categoryType !==undefined  && (localCategory.categoryType.name=='OTHER' || localCategory.categoryType.name=='FOOD' || localCategory.categoryType.name=='KID')">
                            <v-switch
                                id="category_multiselect"
                                class="caption no-margin-padding "
                                v-model="localCategory.multiSelect"
                                label="Multiple selection"
                                hint="This option will allow the client to select multiple items in this category"
                                :persistent-hint=true
                            ></v-switch>

                          </v-col>
                        </v-row>
                      <v-row align="start" >
                      </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="btn_category_close" outlined @click="closeAction()">Close</v-btn>
                    <v-btn id="btn_category_action" color="primary" type="submit" :disabled="!validCategory">{{ localCategory.edit === true ? "Edit" : "Add" }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        name: "PopupCategory",
        props: {
            service:Object,
            categoryDialog:Boolean,
            newCategory: Object,
            closeAction:Function,
            editAction:Function,
        },
        data() {
            return {
                localCategory:{},
                expandExample:true,
                example:undefined,
                dishTypesSelected:[],
                validCategory:true,
            };
        },
        methods:{
          defaultCategoryDescription(event){
            console.log(this.localCategory.categoryType);
            console.log(event);
            if (this.localCategory.categoryType !==undefined && (this.localCategory.categoryType.name!=='OTHER' && this.localCategory.categoryType.name!=='FOOD')){
              this.localCategory.name = this.localCategory.categoryType.displayName;
            }
            // this.$forceUpdate();
          },
            edit(){
              // this.newCategory=this.localDish;
              if (this.localCategory !== undefined && this.localCategory.categoryType.name !== 'OTHER' && this.localCategory.categoryType.name !== 'FOOD'){
                // this.localCategory.name = this.localCategory.categoryType.displayName;
                this.localCategory.ordering = this.localCategory.categoryType.order;
              }
              if (this.localCategory !== undefined && this.localCategory.categoryType.name === 'KID' ){
                this.localCategory.pricingType = this.$store.state.pricingType.find(pt => pt.name==="UNIT");
              }
              if (this.localCategory.categoryType !==undefined && (this.localCategory.categoryType.name=='OTHER' || this.localCategory.categoryType.name=='FOOD')){
                // delete this.localCategory.categoryType;
                // the ordering of the default categories are reserved
                if (this.localCategory.ordering === undefined || this.localCategory.ordering < 100){
                  if (this.service.itemCategories!==undefined){
                    // get the order categories start with the forst one with the ordering = 100 an add one to the following one this will correct any gap
                    this.service.itemCategories.sort(((a, b) => a.ordering - b.ordering));
                    let index=0;
                    for (const cat in this.service.itemCategories) {
                      if (this.service.itemCategories[cat].categoryType !== undefined   && (this.localCategory.categoryType.name=='OTHER' || this.localCategory.categoryType.name=='FOOD')){
                        this.service.itemCategories[cat].ordering=100 + index;
                        index++;
                      }
                    }
                    this.localCategory.ordering = 100 + index;
                  }else{
                    this.localCategory.ordering = 100;
                  }
                }
              }
              this.editAction(this.localCategory,this.service);
            },
            updateLocalDish(){
              let copy;
              let service=this.service;
              if(this.newCategory!==undefined) {
                  copy = JSON.parse(JSON.stringify(this.newCategory));
                  // this.newCategory=JSON.parse(JSON.stringify(editItem));;
                  copy.edit = true;
                  copy.index = service.itemCategories.indexOf(this.newCategory);
              }else{
                  copy={};
                  copy.name=undefined;
                  copy.categoryType=undefined;
                  copy.multiSelect=undefined;
                  if (service.serviceType.name !=="CLASSIC" && service.serviceType.name !=="SHARING" ){
                    copy.categoryType = this.$store.state.categoryType.find(type => type.name === "FOOD")
                    copy.multiSelect=true;
                  }
              }

              if (!copy.pricingType){
                copy.pricingType = this.pricingType[0];
              }
                    // this.newCategory=copy;
                this.localCategory=copy;
                return this.localCategory;
            },
            validateCategory () {
                if (this.$refs.formDish!==undefined && this.$refs.formDish!==undefined){
                    return this.$refs.formDish.validate();
                }
                return true;
            },
          checkCategoryName(v){
              if (this.service.itemCategories!==undefined && (this.newCategory === undefined || v!==this.newCategory.name)){
                return this.service.itemCategories.find(c=>c.name==v) === undefined;
              }else{
                return true;
              }
          }
        },
        computed:{
          dishTypes() {
            // remove the type already used
            // item categor used
            console.log(this.service);
            let excludedTypes =[];
            if (this.service.itemCategories!=undefined && (this.service.serviceType.name==='CLASSIC' || this.service.serviceType.name==='SHARING')){
              excludedTypes = this.service.itemCategories.map(category =>
                  category.categoryType!==undefined && category.categoryType.name!== "OTHER" && category.categoryType.name!== "FOOD" && (this.localCategory.categoryType === undefined || category.categoryType.name!==this.localCategory.categoryType.name) ? category.categoryType.name : undefined
              );
            }else if (this.service.serviceType.name!=='CLASSIC' && this.service.serviceType.name!=='SHARING'){
              if (this.service.itemCategories !==undefined && this.service.itemCategories.length > 0) {
                excludedTypes = this.service.itemCategories.map(category =>
                    category.categoryType!==undefined && category.categoryType.name!== "OTHER" && category.categoryType.name!== "FOOD" && (this.localCategory.categoryType === undefined || category.categoryType.name!==this.localCategory.categoryType.name) ? category.categoryType.name : undefined);
              }

              // Other can be added multiple times
              excludedTypes = excludedTypes.concat(this.$store.state.categoryType.filter(dt => dt.name!=="OTHER" && dt.name!=="KID" && dt.name!=="FOOD" ).map(type => type.name));
            }

            let filteredTypes = this.$store.state.categoryType.filter(dt => !excludedTypes.includes(dt.name));
            return filteredTypes;
          },
          checkCategory(){
              let newCategory=this.newCategory;
              return this.updateLocalDish(newCategory);
          },
          pricingType() {
            return this.$store.state.pricingType;
          },
        },
    }
</script>

<style scoped>

</style>